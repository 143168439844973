(function(){
	var GURID = UserGURID;
	async function checkSDKExistanceAndAddCustomAttribute() {
		if (!window.NOIBUJS) {
			await new Promise(resolve => {
				window.addEventListener('noibuSDKReady', resolve);
			});
		}
		window.NOIBUJS.addCustomAttribute('Customer GURID', GURID);

	}
	checkSDKExistanceAndAddCustomAttribute();

})();